/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const DEFAULT_PROPS = {
  button_transparent: {
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  button: {
    fontWeight: 'normal',
    bgColor: 'bgSecondary',
    color: 'secondaryColorText',
    fontFamily: 'body',
    padding: '4px 16px',
    height: 'unset',
    borderRadius: '4px',
    boxShadow: 'md',
    size: 'md',
    _active: { borderColor: 'transparent' },
    _hover: { borderColor: 'transparent' },
    _focus: { borderColor: 'transparent' },
  },
  option: {
    style: { color: 'black' },
  },
};

export const TEXT_PROPS = {
  default: {},
  title: {
    fontSize: { md: '44px', base: '40px' },
    color: 'mainColorText',
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'normal',
    letterSpacing: '1.5px',
  },
  sub_title: {
    fontFamily: 'headingAlternative',
    color: 'mainColorText',
    fontSize: 'md',
    textAlign: 'center',
  },
  body: {
    fontFamily: 'body',
    color: 'mainColorText',
    fontSize: '19px',
    textAlign: 'center',
  },
};
