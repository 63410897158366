/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////
export { DEFAULT_PROPS } from './default-props';

/////////////////////////////////////////////
// COLOR CONFIGURATION
/////////////////////////////////////////////
export const BG_COLOR = {
  primary: 'rgba(65,100,156,1)',
  secondary: '#FFFFFF',
  alternative: 'rgba(96,136,197,1)',
};

export const TEXT_COLOR = {
  primary: '#fff',
  secondary: 'rgba(65,100,156,1)',
};

export const NAVIGATION_COLOR = {
  base: '#fff',
  icon: '#000',
};

export const ASSET_COLOR = {
  primary: 'rgba(254,254,254,1)',
  // secondary: 'rgba(254,254,254,0.3)',
};
